<template>
    <div class="view_container">
        <div id="content_main">
            <div id="payment_content">
                <br>
                <div id="payment_title">
                    {{ languageData.stran_placilo_naslov }}
                </div>
            </div>

            <div class="pos_terminal_container">
                <div class="pos_terminal_item" @click="goNext()">
                    <span class="terminal_number">1</span><br><br>
                    <img :src="getValinaTerminalImage" alt="Valina Terminal" />
                </div>
                <div class="pos_terminal_item" @click="goNext()">
                    <span class="terminal_number">2</span><br><br>
                    <img :src="getValinaTerminalImage" alt="Valina Terminal" />
                </div>
            </div>

            <div class="pos_terminal_container">
                <div class="pos_terminal_item" @click="goNext()">
                    <span class="terminal_number">3</span><br><br>
                    <img :src="getValinaTerminalImage" alt="Valina Terminal" />
                </div>
                <div class="pos_terminal_item" @click="goNext()">
                    <span class="terminal_number">4</span><br><br>
                    <img :src="getValinaTerminalImage" alt="Valina Terminal" />
                </div>
            </div>
        </div>

        <div id="content_footer">
            <div class="footer_btn_item">
                <button class="btn_footer btn_footer_red" @click="cancel()"><i id="footer_btn_position_left" class="fas fa-times"></i>&nbsp;&nbsp;{{ languageData.preklici_nazaj }}</button>
            </div>
            <div class="footer_btn_item">
                <table id="table_amount" width="100%;" height="92px;">
                    <tbody>
                        <tr id="title">
                            <td>
                                {{ languageData.skupaj_za_placilo }}
                            </td>
                        </tr>
                        <tr id="amount">
                            <td>
                                <!--{{ sum }}<span class="currency">€</span>-->
                                {{ getCartItemsTotal }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="footer_btn_item">
                <div class="footer_empty_screen"></div>
            </div>
        </div>
    </div>
</template>

<script>

import valinaTerminal from '@/assets/icons/valina_terminal.png'
import {formatPrice} from '@/helpers/utilities.js'

export default {
    data: () => ({
        title: "Uporabite plačilno kartico na POS terminalu",
        sum: '44,00'
    }),

    computed: {
        getValinaTerminalImage() {
            return valinaTerminal
        },

        getCartItemsTotal() {
            //console.log(this.$store.getters.price)
            //var number = 350.0;

            //console.log(new Intl.NumberFormat('de-DE').format(number));
            //return new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 2, minimumFractionDigits: 2 }).format(this.$store.getters.price)
            return formatPrice(this.$store.getters.price)
        },

        languageData() {
            return this.$store.getters.languageData
        }
    },

    methods: {
        cancel() {
            this.$store.dispatch('EMPTY_CART');
            this.$router.push({
                name: 'Home'
            })

            //pobriši izbrane datume
            this.$store.dispatch('TRUNCATE_SELECTED_DATES');
        },

        goNext() {
            this.payment();

            return  this.$router.push({
                name: 'Conclusion'
            })
        },

        payment() {
            let cartItems = this.$store.getters.cartItems
            let selectedDates = this.$store.getters.selectedDates

            this.$store.dispatch('PAYMENT', {
                products: cartItems,
                selected_dates: selectedDates
            })
            .then(response => {
                window.console.log(response)
            })
            .catch(error => {
                window.console.error("### Payment.vue@payment ###")
                window.console.error(error)
            })
            .finally(() => {
                window.console.log("payment process done :)")
            })


        }
    },

    created() {

    },

    mounted() {
        //console.log("Payment");
    },

    unmounted() {
        
    }
}

</script>

<style scoped>

    #table_amount #title {
        text-align:center;
        font-size:18px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        color: #656565;
        font-weight:600;
    }

    #table_amount #amount {
        text-align:center;
        font-size:38px;
        color: #007CA4;
        font-weight:500;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    #table_amount {
        background: #ECECEC;
    }

#payment_content {
    width:100%;
    text-align:center;
    margin:5px;
}

#payment_title {
    margin-left:auto;
    margin-right:auto;
    width:40%;
    text-align:center;
    font-size:18px;
    font-family: 'Roboto', sans-serif;
    font-style:normal;
    font-weight:600;
}

.pos_terminal_container {
    margin-top:30px;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:100%;
}

.pos_terminal_item {
    width:38%;
    /*border:1px solid red;*/
    height:100px;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
    height:100%;
    border:1px solid #A3A7A5;
    padding:10px;
}

img {
    width:65%;
}

.terminal_number {
    font-size:20px;
    font-family: 'Roboto', sans-serif;
    font-style:normal;
    font-weight:600;
    color: #007CA4;
}


</style>